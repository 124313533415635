.legal-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
}

.legal-background {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.legal-content-container {
    background-color: rgba(43, 74, 72, 0.9);
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    position: relative;
    z-index: 10;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
}

.legal-content {
    position: relative;
    width: 80%;
    color: white;
    padding-top: 50px;
}

.spacer {
    height: 150px;
    width: 100%;
    position: relative;
}

.spacer .back__button {
    bottom: -20px;
    right: -80px;
    font-size: 30px;
}
.legal-content h1 {
    text-align: center;
    margin-bottom: 40px;
}
