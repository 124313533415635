.intro-container {
    height: 100vh;
}

.scroll {
    position: relative;
    display: flex;
    justify-content: center;
    min-height: 300%;
}

.intro-image-container {
    background-color: #380916;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.intro-content-container {
    background-color: rgba(56, 9, 22, 0.7);
    width: 80%;
    z-index: 10;
    margin-top: 100vh;
    margin-bottom: 100vh;
    color: white;
    padding: 20px;
    height: max-content;
}

.intro-title {
    font-size: 90px;
    margin: 0 0 5px 0;
}

.intro-subtitle {
    font-family: 'Montserrat', sans-serif;
    font-size: 39px;
    margin-top: -15px;
}

.train-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 100% 100%;
    position: absolute
}
.timestamp {
    position: absolute;
    width: 30%;
    z-index: 100;
    top: 20px;
    left: 20px;
}
/*=============================media queries =========================*/


@media screen and (min-width: 600px) {
    .intro-content-container {
        width: 80%;
        padding: 30px
    }
    .intro-title {
        font-size: 112px;
    }
    
    .intro-subtitle {
        font-size: 48px;
    }

}




@media screen and (min-width: 992px) {
    .intro-content-container {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }
    .intro-column-container {
        width: 45%;
    }
    .intro-title {
        margin-top: -20px
    }

    .timestamp {
        width: 20%;
        bottom: 30px;
        left: 30px;
        top: auto;
    }
}
