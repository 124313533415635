body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

h1, h2, h3, h4, h5 {
  text-transform: uppercase;
  font-family: 'Alata', sans-serif;
}

a {
  color: white;
  text-decoration: none;
}